import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-app/components/Dialog';
import Locations from 'components/Locations';

function LocationDialog(props) {
	const {
		classes: classesProp,
		className,
		open,
		...rest
	} = props;

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			fullScreen="md"
			scroll="body"
			title="Unsere Standorte in Lauter & Chemnitz"
			{...rest}
			open={open}
			DialogContentProps={{
				sx: (theme) => ({
					overflowX: 'hidden',
					[theme.breakpoints.down('md')]: {
						'&&': {paddingX: 0}
					}
				})
			}}
		>
			<Locations/>
		</Dialog>
	);
}

LocationDialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	open: PropTypes.bool,
	defaultOpen: PropTypes.bool
};

export default React.memo(LocationDialog);
