import { createClasses } from 'styles';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useDeferredUser } from 'context/user';
import Divider from '@mui/material/Divider';
import AppBar from 'react-app/components/AppBar';
import AppFrame from 'react-app/components/AppFrame';
import AppBanner from 'components/AppBanner';
import AppFooter from 'components/AppFooter';
import { AppHeaderIcons, AppHeaderContent } from 'components/AppHeader';
import { AppDrawerMenu, AppDrawerHeader } from 'components/AppDrawer';
import ThemeSwitch from 'components/ThemeSwitch';
import ErrorBoundary from 'components/ErrorBoundary';
import Brand from 'components/Brand';
import { pathToRegExp } from 'lib/helpers';
import useAppLayout from 'hooks/useAppLayout';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {},
	appBar: {
		...(theme.mixins.backdropBlur(theme.spacing(theme.spacing(1)))),
		backgroundColor: theme.helpers.alpha('background-paper', .8),
		borderBottom: `solid 1px ${theme.config.palette.divider}`,
		justifyContent: 'center',
		boxShadow: 'none'
	},
	drawer: {
		'& > .MuiDrawer-paper': {
			borderWidth: 0,
			[theme.breakpoints.up('lg')]: {
				backgroundColor: theme.helpers.alpha('background-paper', .8)
			}
		}
	},
	drawerHeader: {
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		[theme.breakpoints.down('lg')]: {
			marginTop: 0,
			borderBottom: `solid 1px ${theme.config.palette.divider}`
		}
	},
	drawerFooter: {
		backgroundColor: theme.config.palette.background.overlay
	},
	toolbarMiddle: {
		flex: 1,
		marginLeft: 0,
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('xl')]: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2)
		}
	}
}), {
	name: 'AppLayout'
});

export default function AppLayout(props) {
	const classes = useClasses(props);
	const pageData = useOutletContext();

	const { pathname, id: pageId, options = {} } = pageData;
	const noFooter = options.noFooter || (options.noFooterMatch && pathToRegExp(options.noFooterMatch).exec(pathname));
	const [ {userType} ] = useDeferredUser();
	const alert = appData.alert?.[userType] || appData.alert?.general;

	const {
		browser,
		drawerOpen,
		drawerRef,
		handleDrawerToggle,
		handleDrawerClose
	} = useAppLayout();

	const { isWidthUp, breakpoint } = browser;
	const smUp = isWidthUp('sm', breakpoint);
	const lgUp = isWidthUp('lg', breakpoint);

	const AppBarProps = {
		color: 'default',
		className: classes.appBar,
		toolbarLeftContent: <Brand variant="button"/>,
		toolbarRightContent: <AppHeaderIcons/>,
		toolbarMiddleContent: smUp ? <AppHeaderContent/> : null,
		classes: {
			toolbarMiddle: classes.toolbarMiddle
		}
	};

	const AppDrawerProps = {
		ref: drawerRef,
		drawerWidth: lgUp ? 270 : 300,
		headerCloseButton: !lgUp,
		headerContent: lgUp ? null : <Brand variant="button" onClick={handleDrawerClose}/>,
		variant: lgUp ? 'persistent' : 'temporary',
		footerDivider: false,
		fixedHeader: true,
		fixedFooter: true,
		hideScrollbar: true,
		footerContent: <ThemeSwitch/>,
		classes: {
			root: classes.drawer,
			drawerHeader: classes.drawerHeader,
			drawerFooter: classes.drawerFooter
		},
		children: (
			<>
				{!lgUp && (
					<>
						<AppDrawerHeader onClose={handleDrawerClose}/>
						<Divider/>
					</>
				)}
				<AppDrawerMenu
					pageId={pageId}
					pathname={pathname}
					onClick={lgUp ? null : handleDrawerClose}
				/>
			</>
		)
	};

	const alertData = alert?.pages?.[pageData.id] ? alert.pages[pageData.id] : alert;

	return (
		<AppFrame
			{...props}
			className={classes.root}
			open={drawerOpen}
			clippedDrawer={lgUp}
			onClose={handleDrawerClose}
			onToggle={handleDrawerToggle}
			AppBarComponent={AppBar}
			AppBarProps={AppBarProps}
			AppDrawerProps={AppDrawerProps}
		>
			<ErrorBoundary>
				{alertData?.text && !alertData.exludePages?.includes(pageData.id) && !['admin', 'test'].includes(pageData.id) && (
					<AppBanner {...alertData}/>
				)}

				<Outlet context={pageData}/>
				{!noFooter && (
					<AppFooter/>
				)}
			</ErrorBoundary>
		</AppFrame>
	);
}
