/* global WEBPACK_DEV WEBPACK_APP_VERSION WEBPACK_IS_PUBLIC */

import * as React from 'react';
import { createClasses, useTheme } from 'styles';
import Stack from '@mui/material/Stack';
import Link from 'components/Link';
import MenuButton from 'components/MenuButton';
import MenuItemLink from 'components/MenuItemLink';
import Brand from 'components/Brand';
import ContactIcons from 'components/ContactIcons';
import ShopButtonSvg from 'components/ShopButtonSvg';
import LocationInfo from 'components/LocationInfo';
import AppFooterContainer from './AppFooterContainer';
import AppFooterContent from './AppFooterContent';
import AppFooterBottom from './AppFooterBottom';
import AppFooterParnter from './AppFooterParnter';
import { useCookieConsent } from 'lib/cookie-consent';
import { useDeferredUser } from 'context/user';
import { useThemeSwitch } from 'components/ThemeSwitch';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	social: {
		margin: theme.spacing(0, -.5),
		fontSize: '3rem'
	},
	menu: {
		listStyle: 'none',
		margin: 0,
		[theme.breakpoints.down('xl')]: {
			paddingLeft: 0
		},
		'& > li': {
			lineHeight: 1.4,
			fontWeight: theme.typography.fontWeightMedium,
			marginBottom: {xs: 1.5, md: 1}
		},
		'& > li:last-of-type': {
			marginBottom: 0
		}
	},
	logo: {
		'&&': {
			margin: '0 auto',
			[theme.breakpoints.down('desktop')]: {
				maxHeight: 90,
				minHeight: 90,
				maxWidth: '100%',
				width: '100%',
				'& > img': {
					maxHeight: 90,
					minHeight: 90,
					width: '100%',
					height: 'auto'
				}
			}
		}
	}
}), {
	name: 'AppFooter'
});

const menuItems = ['news', 'sale', 'discontinued', 'service', 'team', 'exhibition', 'events1', 'woodAcademy', 'catalogs'].map(id => {
	const item = appData.pages[id];

	return {
		id: item.id,
		title: item.title,
		pathname: item.pathname
	};
});

export default function AppFooter(props) {
	const classes = useClasses(props);
	const theme = useTheme();
	const { darkMode } = theme.config;
	const { themeSwicth } = useThemeSwitch();

	const [ {userType, isRetail}, {setUserDialog} ] = useDeferredUser();
	const { handleCookieConsentReset } = useCookieConsent();

	const menuButtonRef = React.useRef();

	function handleMenuItemClick(event) {
		event.preventDefault();
		const closeMenu = menuButtonRef?.current?.close;
		if (typeof closeMenu === 'function') {
			closeMenu();
		}
	}

	function handleCookieConsentClick(event) {
		handleCookieConsentReset(event);
		handleMenuItemClick(event);
	}

	function handleUserTypeClick(event) {
		setUserDialog(true);
		handleMenuItemClick(event);
	}

	const { pages } = appData;

	return (
		<AppFooterContainer>
			<AppFooterContent>
				<Stack
					alignItems="center"
					direction={{xs: 'column', md: 'row'}}
					justifyContent="space-between"
					width={{xs: 'auto', md: '100%', desktop: '80%', 'xl': '100%'}}
				>
					<Stack
						direction={{xs: 'column', sm: 'row'}}
						gap={{xs: 4, sm: 6, desktop: 3, xl: 10}}
						width={{xs: 'auto', xl: '45%'}}
					>
						<LocationInfo
							location={isRetail ? 'lauter2' : 'lauter'}
							title={isRetail ? 'Ausstellung Lauter' : 'Holzgroßhandel Lauter'}
						/>
						<LocationInfo
							location="chemnitz"
							title={isRetail ? 'Ausstellung Chemnitz' : 'Holzgroßhandel Chemnitz'}
						/>
					</Stack>

					<Stack
						my={{xs: 4, md: 0}}
						width={{xs: 'auto', xl: '35%'}}
						alignItems="center"
					>
						<Brand width={200} height={98}/>
						<Stack direction="row" marginY={1.5} spacing={.5}>
							<ContactIcons
								className={classes.social}
								shop={false}
								pinterest
							/>
						</Stack>
						<ShopButtonSvg/>
					</Stack>

					<Stack
						component="ul"
						width={{xs: 'auto', desktop: '17%'}}
						className={classes.menu}
					>
						{menuItems.filter(item => !pages[item.id]?.userType || pages[item.id]?.userType === userType).slice(0, 8).map((item, key) => (
							<li key={key}>
								<Link
									to={item.pathname}
									color="text.primary"
								>
									{item.title}
								</Link>
							</li>
						))}
					</Stack>
				</Stack>

				<Stack
					direction={{xs: '', md: 'row'}}
					alignItems={{xs: 'center', xl: 'flex-end'}}
					flexWrap={{desktop: 'wrap'}}
					gap={3}
					mt={{xs: 4, desktop: 0}}
					mb={{xs: 2, desktop: 0}}
					justifyContent="flex-end"
					alignContent="center"
					width={{xs: 'auto', desktop: '28%'}}
				>
					<AppFooterParnter/>
				</Stack>
			</AppFooterContent>

			<AppFooterBottom>
				<MenuButton
					ref={menuButtonRef}
					menuItems={[
						<MenuItemLink
							key="coloMode-setting"
							onClick={themeSwicth}
						>
							{`${darkMode ? 'Helles' : 'Dunkles'} Farbschema`}
						</MenuItemLink>,
						<MenuItemLink
							key="cookie-setting"
							onClick={handleCookieConsentClick}
						>
							Cookie-Einstellungen
						</MenuItemLink>,
						<MenuItemLink
							key="userType-setting"
							onClick={handleUserTypeClick}
						>
							Willkommenbildschirm
						</MenuItemLink>
					]}
				>
					Einstellungen
				</MenuButton>
			</AppFooterBottom>
		</AppFooterContainer>
	);
}
