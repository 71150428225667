import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import ShopButton from 'components/ShopButton';
import AppHeaderButtons from 'components/AppHeader/AppHeaderButtons';
import LocationContact from 'components/LocationContact';
import ContactIcons from 'components/ContactIcons';
import { useBrowser } from 'react-app/context/browser';

function AppDrawerHeader(props) {
	const {
		classes: classesProp,
		className,
		onClose,
		...rest
	} = props;

	const browser = useBrowser();

	const { isWidthUp, breakpoint } = browser;
	const smUp = isWidthUp('sm', breakpoint);
	const mdUp = isWidthUp('md', breakpoint);
	const lgUp = isWidthUp('lg', breakpoint);
	const tabletUp = isWidthUp('tablet', breakpoint);

	return (
		<Stack marginLeft={1.5} marginRight={.5} marginY={2} gap={1.5} {...rest}>
			{!lgUp && (
				<>
					{!tabletUp && (
						<ShopButton fullWidth/>
					)}
					<AppHeaderButtons
						flexWrap="nowrap"
						direction="column"
						justifyContent="center"
						showShopButton={false}
						showContactButton
						showHqButton
						showUserTypeButton={!smUp}
						ButtonProps={{onClick: onClose, fullWidth: true}}
					/>
				</>
			)}

			{!mdUp && (
				<Stack direction="row" justifyContent="space-between" gap={1.5} mb={-.25}>
					<LocationContact name="lauter"/>
					<LocationContact name="chemnitz"/>
				</Stack>
			)}

			{!smUp && (
				<Stack direction="row" justifyContent="center" marginY={-.75}>
					<ContactIcons
						pinterest
						shop={false}
						onClick={onClose}
					/>
				</Stack>
			)}
		</Stack>
	);
}

AppDrawerHeader.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	onClose: PropTypes.func
};

export default React.memo(AppDrawerHeader);
