import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import PhoneIcon from 'react-app/icons/Phone';
import WatchIcon from '@mui/icons-material/WatchLaterOutlined';
import LocationContactItem from './LocationContactItem';
import ContactPhone from 'components/ContactPhone';
import OpeningHours from 'components/OpeningHours';

const useClasses = createClasses((theme) => ({
	root: {},
	item: {
		display: 'flex',
		alignItems: 'center',
		fontSize: theme.typography.pxToRem(12)
	},
	icon: {
		'&&': {
			marginRight: theme.spacing(.5),
			fontSize: theme.typography.pxToRem(12)
		}
	},
	text: {
		lineHeight: 1.35
	}
}), {
	name: 'LocationContact'
});

function LocationContact(props) {
	const {
		classes: classesProp,
		className,
		name,
		ContactPhoneProps,
		OpeningHoursProps,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<LocationContactItem
			{...rest}
			name={name}
			className={classes.root}
		>
			<div className={classes.item}>
				<PhoneIcon className={classes.icon}/>
				<ContactPhone
					name={name}
					{...ContactPhoneProps}
					className={classes.text}
				/>
			</div>
			<div className={classes.item}>
				<WatchIcon className={classes.icon}/>
				<OpeningHours
					name={name}
					shortVariant
					{...OpeningHoursProps}
					className={classes.text}
				/>
			</div>
		</LocationContactItem>
	);
}

LocationContact.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	ContactPhoneProps: PropTypes.object,
	OpeningHoursProps: PropTypes.object
};

export default React.memo(LocationContact);
