import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Typography from '@mui/material/Typography';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {
		color: 'inherit',
		display: 'flex',
		alignItems: 'center',
		fontSize: 'inherit',
		fontWeight: 'inherit',
		lineHeight: 'inherit'
	}
}), {
	name: 'OpeningHours'
});

const weekday = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const weekdayLong = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

function convertTime(time) {
	if (!time) return;
	time = time.toString();
	return time.indexOf(':') === -1 ? time + ':00' : time;
}

function convertOpeningHours(openingHours = []) {
	const result = {};
	openingHours.forEach((item) => {
		let [day, time] = item || [];
		day = day.split(' - ');
		time = time.replace(/ Uhr/gi, '').split(' - ');

		time = time.map(n => parseFloat(n));
		const from = weekday.indexOf(day[0]);
		const to = weekday.indexOf(day[day.length - 1]);

		for (let i = from; i <= to; i++) {
			result[weekday[i]] = [weekday[i], time[0], time.at(-1)];
		}
	});

	return weekday.map((val, key) => result[val] || null);
}

function getOpeningHours(data = [], shortVariant = false) {
	// const date = new Date('8-20-2019 7:00');
	const date = new Date();
	const now = date.getHours() + '.' + date.getMinutes();

	const openingHours = convertOpeningHours(data);
	const currentDay = date.getDay();
	const day = openingHours[currentDay];
	const text = shortVariant ? '' : ' geöffnet';

	if (day) {
		if (now >= day[1] && now < day[2] || now >= day[3] && now < day[4]) {
			const time = convertTime(day[day.length-1]);
			if (time) {
				return `Heute bis ${time} Uhr${text}`;
			}
		}
		if (now < day[1] || now < day[3]) {
			const time = convertTime(day[3] && now < day[3] ? day[3] : day[1]);
			if (time) {
				return `Heute ab ${time} Uhr${text}`;
			}
		}
	}

	let next = '';
	let nextDay = currentDay + 1;

	if (openingHours[nextDay]?.[1] && openingHours[nextDay]?.[1] !== 'geschlossen') {
		next = 'Morgen';
	} else {
		nextDay = null;
		openingHours.forEach((item, key) => {
			if (item && nextDay === null && key >= nextDay) {
				nextDay = key;
			}
		});
		next = weekdayLong[nextDay];
	}

	if (openingHours[nextDay]) {
		const time = convertTime(openingHours[nextDay][1]);
		if (time) {
			return `${next} ab ${time} Uhr${text}`;
		}
	}

	return 'Heute geschlossen';
}

function OpeningHours(props) {
	const {
		classes: classesProp,
		className,
		name,
		shortVariant,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Typography
			component="div"
			{...rest}
			className={classes.root}
		>
			{getOpeningHours(appData.contact.openingHours[name], shortVariant)}
		</Typography>
	);
}

OpeningHours.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	shortVariant: PropTypes.bool
};

export default React.memo(OpeningHours);
