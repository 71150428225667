import * as React from 'react';
import MenuItemLinkBase from 'react-app/components/MenuItemLink';
import Link from 'components/Link';

const LinkProps = {
	color: 'inherit'
};

export default React.memo(React.forwardRef(function MenuItem(props, ref) {
	return <MenuItemLinkBase LinkComponent={Link} LinkProps={LinkProps} {...props} ref={ref}/>;
}));
