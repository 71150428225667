import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Link from 'components/Link';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {
		letterSpacing: 'normal',
		display: 'flex',
		alignItems: 'center'
	}
}), {
	name: 'ContactPhone'
});

function ContactPhone(props) {
	const {
		classes: classesProp,
		className,
		name,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Link
			color="text.primary"
			href={`tel:${appData.contact.phone[name]}`}
			{...rest}
			className={classes.root}
		>
			{appData.contact.phone[name]}
		</Link>
	);
}

ContactPhone.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	name: PropTypes.string.isRequired
};

export default React.memo(ContactPhone);
