import * as React from 'react';
import LocationCard from 'components/LocationCard';
import LocationsCarousel from 'components/LocationsCarousel';

function Locations() {
	return (
		<LocationsCarousel>
			<LocationCard id="lauter"/>
			<LocationCard id="chemnitz"/>
			<LocationCard id="lauter2"/>
			<LocationCard id="lauter4"/>
		</LocationsCarousel>
	);
}

export default React.memo(Locations);
