import * as React from 'react';
import DrawerMenu from 'react-app/components/DrawerMenu';
import Link from 'components/Link';
import { useDeferredUser } from 'context/user';
import { appData } from 'config/data.cjs';
import menuIcons from './drawerMenuIcons';

const getMenuItem = (userType, items = JSON.parse(JSON.stringify(appData.menu))) => {
	return items.filter(item => {
		const page = appData.pages[item.id] || {};
		if (!page) return;
		if (page.redirectToShop) {
			page.pathname = item.pathname = page.shopLink?.[userType] || page.shopLink;
		}
		if (page.menuTitle?.[userType] && !item.title) {
			item.title = page.menuTitle[userType];
		}
		if (Array.isArray(item.children)) {
			item.children = getMenuItem(userType, item.children);
		}
		return !item.hidden && (!page.userType || page.userType === userType);
	});
};

const DrawerMenuNavItemProps = {
	NavLinkComponent: Link,
	dense: true,
	spacingValue: 0
};

function AppDrawerMenu(props) {
	const [ { userType } ] = useDeferredUser();

	const menuItems = React.useMemo(() => (
		getMenuItem(userType)
	), [userType]);

	return (
		<DrawerMenu
			{...props}
			icons={menuIcons}
			menuItems={menuItems}
			DrawerMenuNavItemProps={DrawerMenuNavItemProps}
		/>
	);
}

export default React.memo(AppDrawerMenu);
