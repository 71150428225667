import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Hidden from 'react-app/components/Hidden';
import PhoneIcon from 'react-app/icons/Phone';
import WatchIcon from '@mui/icons-material/WatchLaterOutlined';
import LocationContact, { LocationContactItem } from 'components/LocationContact';
import ContactPhone from 'components/ContactPhone';
import OpeningHours from 'components/OpeningHours';
import { useDeferredUser } from 'context/user';

const StackProps = {
	paddingX: {
		desktop: .5,
		xl: .75
	}
};

function AppHeaderContact(props) {
	const [{isRetail}] = useDeferredUser();

	const name = isRetail ? 'lauter2' : 'lauter';

	return (
		<Hidden mdDown noSsr>
			<Stack direction="row" alignItems="center" marginX={{xs: 'auto', xl: 17}} gap={{md: 1.5, desktop: 0}} {...props}>
				<Hidden desktopUp>
					<LocationContact {...StackProps} name={name}/>
					<LocationContact {...StackProps} name="chemnitz"/>
				</Hidden>

				<Hidden desktopDown>
					<Stack {...StackProps}>
						<PhoneIcon/>
					</Stack>

					<LocationContactItem {...StackProps} name={name}>
						<ContactPhone name={name}/>
					</LocationContactItem>

					<LocationContactItem {...StackProps} name="chemnitz">
						<ContactPhone name="chemnitz"/>
					</LocationContactItem>

					<Stack {...StackProps} marginLeft={{desktop: 1, xl: 1.5}}>
						<WatchIcon/>
					</Stack>

					<LocationContactItem {...StackProps} name={name}>
						<OpeningHours name={name} shortVariant/>
					</LocationContactItem>

					<LocationContactItem {...StackProps} name="chemnitz">
						<OpeningHours name="chemnitz" shortVariant/>
					</LocationContactItem>
				</Hidden>
			</Stack>
		</Hidden>
	);
}

AppHeaderContact.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string
};

export default React.memo(AppHeaderContact);
