import * as React from 'react';
import CardCarousel from 'components/CardCarousel';

const CardCarouselProps = {
	CarouselProps: {
		cardSizes: {xs: 260},
		navButtonOffsetY: -14,
		navButtonOffsetX: -2
	},
	ContainerProps: {
		disableGutters: true,
		gridMode: false,
		centered: true,
		// gridMode: true,
		// gridTemplateColumns: {
		// 	xs: '1fr',
		// 	md: 'repeat(4, 1fr)'
		// }
	}
};

function LocationsCarousel(props) {
	const { children, CardProps, ...rest } = props;

	return (
		<CardCarousel
			containerBpr="desktop"
			{...CardCarouselProps}
			{...rest}
		>
			{children}
		</CardCarousel>
	);
}

export default React.memo(LocationsCarousel);
