import { createClasses, useTheme } from 'styles';
import Stack from '@mui/material/Stack';
import Typography from 'react-app/mui/material/Typography';
import Divider from 'react-app/mui/material/Divider';
import Img from 'react-app/components/Img';
import Link from 'components/Link';
import { appData } from 'config/data.cjs';
import {
	logo_hw,
	logo_hw_mono,
	logo_fhh,
	logo_fhh_mono,
	logo_holzfabrik,
	logo_holzfabrik_mono,
	logo_HvH_Mitglied_weidauer,
	logo_HvH_Mitglied_weidauer_mono,
	logo_tib,
	logo_tib_mono
} from 'config/images';

const { isFactory } = appData;

const useClasses = createClasses((theme) => ({
	root: {},
	logo: {
		'&&': {
			margin: '0 auto',
			[theme.breakpoints.down('desktop')]: {
				maxHeight: 90,
				minHeight: 90,
				maxWidth: '100%',
				width: '100%',
				'& > img': {
					maxHeight: 90,
					minHeight: 90,
					width: '100%',
					height: 'auto'
				}
			}
		}
	}
}), {
	name: 'AppFooter'
});

export default function AppFooterContainer(props) {
	const { children, ...rest } = props;
	const theme = useTheme();
	const { darkMode } = theme.config;
	const classes = useClasses(props);

	return (
		<Stack direction="column" gap={{xs: 2, xl: 2.5}} {...rest} className={classes.root}>
			<Typography textAlign="center" component="h3" width={{desktop: '100%'}} maxWidth={{desktop: 247, xl: 255}} alignSelf={{desktop: 'flex-end'}}>
				Unsere Partner
				<Divider sx={{mt: .75}}/>
			</Typography>
			<Stack direction={{xs: 'column', sm: 'row'}} gap={{xs: 2, xl: 2.5}} flexWrap="wrap" justifyContent={{xs: 'center', desktop: 'flex-end'}}>
				{isFactory ? (
					<Link href="https://holzweidauer.de">
						<Img
							{...(darkMode ? logo_hw_mono : logo_hw)}
							className={classes.logo}
							alt="Holz Weidauer"
							width={123}
							height={60}
						/>
					</Link>
				) : (
					<Link href="https://www.holzfabrik-erzgebirge.de">
						<Img
							{...(darkMode ? logo_holzfabrik_mono : logo_holzfabrik)}
							className={classes.logo}
							alt="Logo Holzfabrik Erzgebirge"
							width={98}
							height={80}
						/>
					</Link>
				)}
				<Link href="https://tib-schrauben.de">
					<Img
						{...(darkMode ? logo_tib_mono : logo_tib)}
						className={classes.logo}
						alt="Logo Technischer Industriebedarf"
						width={isFactory ? 100 : 133}
						height={isFactory ? 60 : 80}
					/>
				</Link>
				<Link href="http://www.holzkompetenz-erzgebirge.de">
					<Img
						{...(darkMode ? logo_fhh_mono : logo_fhh)}
						className={classes.logo}
						alt="Logo Holzkompetenz Erzgebirge"
						width={94}
						height={60}
					/>
				</Link>
				<Link href="https://www.holz-von-hier.eu">
					<Img
						{...(darkMode ? logo_HvH_Mitglied_weidauer_mono : logo_HvH_Mitglied_weidauer)}
						className={classes.logo}
						alt="Logo HvH-Mitglied Weidauer"
						width={132}
						height={60}
					/>
				</Link>
			</Stack>
		</Stack>
	);
}
