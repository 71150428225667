import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import AppHeaderButtons from './AppHeaderButtons';
import AppHeaderContact from './AppHeaderContact';

function AppHeaderContent(props) {
	const {
		classes: classesProp,
		className,
		pathname,
		...rest
	} = props;

	return (
		<Stack
			direction="row"
			alignItems="center"
			{...rest}
		>
			<AppHeaderContact/>
			<AppHeaderButtons ml={{sm: 'auto'}}/>
		</Stack>
	);
}

AppHeaderContent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	pathname: PropTypes.string
};

export default React.memo(AppHeaderContent);
