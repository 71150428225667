import * as React from 'react';
import Stack from '@mui/material/Stack';
import Img from 'react-app/components/Img';
import Button from 'components/Button';
import Link from 'components/Link';
import IconButton from '@mui/material/IconButton';
import Headline from 'components/Headline';
import PhoneIcon from 'react-app/icons/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationIcon from '@mui/icons-material/LocationOn';
import WatchIcon from '@mui/icons-material/WatchLater';
import EyeIcon from 'react-app/icons/Eye';
import InfoIcon from '@mui/icons-material/Info';
import { ContactItem, OpeningHoursItem } from 'components/ContactUtils';
import { appData } from 'config/data.cjs';
import {
	location_lauter,
	location_lauter2,
	location_lauter4,
	location_chemnitz
} from 'config/images';

const images = {
	lauter: location_lauter,
	chemnitz: location_chemnitz,
	lauter2: location_lauter2,
	lauter4: location_lauter4
};

function LocationCard(props) {
	const { id, href, title, subtitle, buttonTitle, ButtonProps, ImgProps } = props;
	const { company, contact } = appData;
	const { openingHours } = contact || {};

	const image = images[id];

	return (
		<Stack gap={1.5} flex={1} minWidth={260} maxWidth={360} width="100%">
			<Stack position="relative">
				<Img
					width={360}
					height={240}
					{...image}
					alt={company.title[id]}
					showPlaceholder
					fullWidth
					rounded
					{...ImgProps}
				/>
				{Boolean(contact.www[id]) && (
					<IconButton
						size="small"
						sx={{position: 'absolute', top: 0, right: 0}}
						href={contact.www[id]}
						component={Link}
						title="mehr Infos"
					>
						<InfoIcon fontSize="large"/>
					</IconButton>
				)}
			</Stack>
			<Headline
				mb={.5}
				title={title || company.title[id]}
				subtitle={subtitle ?? company.locationName[id]}
			/>
			<ContactItem Icon={PhoneIcon} href={`tel:${contact.phone[id]}`}>
				{contact.phone[id]}
			</ContactItem>
			<ContactItem Icon={EmailIcon} href={`mailto:${contact.email[id]}`}>
				{contact.email[id]}
			</ContactItem>
			<ContactItem Icon={LocationIcon} href={contact.map[id]}>
				{contact.address[id][0]}<br/>
				{contact.address[id][1]}
			</ContactItem>
			{Array.isArray(openingHours?.[id]) && openingHours[id].length > 0 && (
				<ContactItem Icon={WatchIcon}>
					<Stack>
						<OpeningHoursItem data={openingHours?.[id][0]}/>
						<OpeningHoursItem data={openingHours?.[id][1]}/>
					</Stack>
				</ContactItem>
			)}
			{href ? (
				<Button
					fullWidth
					sx={{mt: 1}}
					variant="contained"
					href={href}
					startIcon={<InfoIcon/>}
					{...ButtonProps}
				>
					{buttonTitle || 'mehr erfahren'}
				</Button>
			) : Boolean(contact.tour[id]) && (
				<Button
					fullWidth
					sx={{mt: 1}}
					variant="contained"
					title={`360° Rundgang ${company.title[id]}`}
					href={contact.tour[id]}
					startIcon={<EyeIcon/>}
					{...ButtonProps}
				>
					360° Rundgang
				</Button>
			)}
		</Stack>
	);
}

export default React.memo(LocationCard);
