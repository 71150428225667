import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { createClasses } from 'styles';
import Typography from '@mui/material/Typography';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {},
	title: {
		lineHeight: 1.25,
		fontWeight: theme.typography.fontWeightLight,
		fontSize: theme.typography.pxToRem(10)
	},
	content: {
		lineHeight: 1.2,
		fontWeight: theme.typography.fontWeightBold,
		fontSize: theme.typography.pxToRem(12)
	}
}), {
	name: 'LocationContactItem'
});

function LocationContactItem(props) {
	const {
		classes: classesProp,
		children,
		name,
		title = appData.company.locationName[props.name],
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Stack color="inherit" {...rest}>
			{title && (
				<Typography
					color="inherit"
					variant="caption"
					component="div"
					noWrap
					className={classes.title}
				>
					{title}
				</Typography>
			)}
			{children && (
				<Typography
					color="inherit"
					variant="h6"
					component="div"
					noWrap
					className={classes.content}
				>
					{children}
				</Typography>
			)}
		</Stack>
	);
}

LocationContactItem.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string
};

export default React.memo(LocationContactItem);
