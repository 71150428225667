import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from 'components/Link';

export function ContactItemText(props) {
	const {children, sx, href, ...rest} = props;
	const Component = href ? Link : Typography;

	return (
		<Component
			fontSize="inherit"
			color="text.primary"
			{...rest}
			href={href}
			sx={theme => ({
				...theme.mixins.textOverflow,
				lineHeight: 1.25,
				...sx
			})}
		>
			{children}
		</Component>
	);
}

export function OpeningHoursItem(props) {
	const { data } = props;

	return (
		Array.isArray(data) && data.length > 0 && (
			<Stack gap={3} direction="row" alignItems="center" justifyContent="space-between">
				<ContactItemText>
					{data[0]}
				</ContactItemText>
				<ContactItemText>
					{data[1]}
				</ContactItemText>
			</Stack>
		)
	);
}

export function ContactItem(props) {
	const { Icon, href, children, title } = props;

	return (
		<Stack gap={1.5} direction="row" alignItems="center" fontSize="0.875rem">
			<Avatar sx={{ width: 32, height: 32, backgroundColor: 'default.main' }}>
				<Icon sx={{ fontSize: 18, color: 'text.primary' }}/>
			</Avatar>
			{href ? (
				<ContactItemText href={href} title={title}>
					{children}
				</ContactItemText>
			) : children}
		</Stack>
	);
}