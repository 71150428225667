import * as React from 'react';
import ScrollableCards from 'components/ScrollableCards';
import CardContainer from 'components/CardContainer';
import { useBrowser } from 'react-app/context/browser';

function CardCarousel(props) {
	const {
		children,
		ContainerProps,
		CarouselProps,
		CardProps,
		containerBpr,
		centered,
		...rest
	} = props;

	const browser = useBrowser();
	const { isWidthUp, breakpoint } = browser;
	const isBreakpoint = isWidthUp(containerBpr || 'xs', breakpoint);

	return (
		containerBpr && isBreakpoint ? (
			<CardContainer
				{...rest}
				{...ContainerProps}
				CardProps={{
					...CardProps,
					...ContainerProps?.CardProps
				}}
				centered={centered || ContainerProps?.centered}
			>
				{children}
			</CardContainer>
		) : (
			<ScrollableCards
				{...rest}
				{...CarouselProps}
				CardProps={{
					...CardProps,
					...CarouselProps?.CardProps
				}}
			>
				{children}
			</ScrollableCards>
		)
	);
}

export default React.memo(CardCarousel);